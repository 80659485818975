// This file shared across SB, blog, my-account

import initNavbar from "./navbar"
import initFooter from "./footer"
import { setupTabs } from "../components/tabs"
import { setupAccordions } from "../components/content/Accordion"

initNavbar()
initFooter()
setupTabs()
setupAccordions()
